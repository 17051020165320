// App.js

import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import App from './App';
import Rarity from './rarity/Rarity';
import Upgrade from './upgrade/Upgrade';

class Racine extends Component {
  render() {
    return (
    <Router>
    <Switch>
        <Route exact path='/' component={App} />
        <Route path='/list' component={Rarity} />
        <Route path='/upgrade' component={Upgrade} />
    </Switch>
    </Router>
    );
  }
}

export default Racine;