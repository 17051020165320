import React from 'react';
import Papa from 'papaparse';

import './Rarity.css';

import './Bootstrap.css';

class Rarity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            page: 1
        };

        this.getData = this.getData.bind(this);
    }

    componentWillMount() {
        this.getCsvData();
    }

    fetchCsv() {
        return fetch('data.csv').then(function (response) {
            let reader = response.body.getReader();
            let decoder = new TextDecoder('utf-8');

            return reader.read().then(function (result) {
                return decoder.decode(result.value);
            });
        });
    }

    getData(result) {
        console.log("RESSSS", result);
        this.setState({data: result.data});
        this.orderChange();
    }

    async getCsvData() {
        let csvData = await this.fetchCsv();

        Papa.parse(csvData, {
            complete: this.getData
        });
    }


    onChangePage(pageId) {
      if(pageId < 1 || pageId > 11)
        return;
      this.setState({
        page: pageId
      })
    }

    orderChange() {
      let type = document.getElementById("inputGroupSelect01").value;
      let list = this.state.data;
      if(type == "id") {
        list.sort((a, b) => (parseInt(a[1]) > parseInt(b[1])) ? 1 : -1);
      } else {
        list.sort((a, b) => (parseInt(a[8]) > parseInt(b[8])) ? 1 : -1);
      }
      this.setState({data: list});
    }

    render() {
        return (
          <div className="App">
            <div className="container">
              <ul className="pagination">
                <a href="/" type="button" className="btn btn-light" style={{marginRight: '30px'}}><i className='fa fa-angle-left'></i> Back to website</a>
                <li className={`page-item ${this.state.page==1? "disabled" : ""}`} onClick={() => { this.onChangePage(this.state.page-1)}}><a className="page-link">Previous</a></li>
                {[1,2,3,4,5,6,7,8,9,10,11].map(el => <li key={el} className={`page-item ${this.state.page==el ? "active" : ""}`} onClick={() => { this.onChangePage(el)}}><a className="page-link">{el}</a></li>)}
                <li className={`page-item ${this.state.page==11? "disabled" : ""}`} onClick={() => { this.onChangePage(this.state.page+1)}}><a className="page-link">Next</a></li>
                <div className="input-group" style={{marginLeft: '30px'}}>
                  <div className="input-group-prepend">
                    <label className="input-group-text" for="inputGroupSelect01">Sort by:</label>
                  </div>
                  <select className="custom-select" id="inputGroupSelect01" onChange={() => { this.orderChange()}}>
                    <option selected value="id">ID</option>
                    <option disabled value="rank">Rank (Check on howrare!)</option>
                  </select>
                </div>
              
              </ul>

              <div class="alert alert-primary" role="alert">
                <i className='fa fa-info-circle'></i> Ranking is now available on <a href="https://howrare.is/nogoalfaces" target="_blank" rel="noreferrer">howrare.is/nogoalfaces</a>, with attribute filters and much more!
              </div>

              <div className="row">

              {
                this.state.data.slice((this.state.page-1)*50, this.state.page*50).map(el => 

                  <div className="pc15" key={el[1]}>
                  <div className="card" style={{width: '18rem;'}}>
                    <img className="card-img-top" src={el[5]} alt="Card image cap"/>
                    <div className="card-body">
                      <h5 className="card-title">{el[3]}</h5>
                      <p className="card-text">
                        {
                        el[4] != null ? 
                          el[4].includes("not a single one") ? "" : el[4].substring(120).slice(0, -1).split("| ").map(ele => 
                            <p>{ele.replace("[Uncommon]", "(20%)").replace("[Rare]", "(10%)").replace("[Epic]", "(4%)").replace("[Legendary]", "(1%)")}</p>
                          )
                          : ""
                        }
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                    {/* <li class="list-group-item">
                      <p>Score: ~{el[7]}</p>
                      <p>Rank: {el[8]}</p>
                    </li> */}

                      <li class="list-group-item"><a className="a-nowrap" href={"http://explorer.solana.com/address/"+el[0]} target="_blank" rel="noreferrer">{
                        el[0] != null ? el[0].substring(0,16)+"..."+el[0].substring(el[0].length - 4) : ""
                      }</a></li>
                    </ul>
                  </div>
                </div>
                )
              }
              </div >
            </div>
          </div>
        );
    }
}

export default Rarity;