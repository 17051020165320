const {
  PublicKey,
  Transaction,
  TransactionInstruction,
  SYSVAR_RENT_PUBKEY,
} = require('@solana/web3.js');
var web3 = require("@solana/web3.js");

const TokenInstructions = require("@project-serum/serum").TokenInstructions;

const TOKEN_PROGRAM_ID = new PublicKey(
  TokenInstructions.TOKEN_PROGRAM_ID.toString()
);

const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
);

const findAssociatedTokenAddress = async (
  walletAddress,
  tokenMintAddress
) => {
  return (
    await PublicKey.findProgramAddress(
      [walletAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0]
};

export const sendSols = async(
  connection,
  wallet,
  refaddr
) => {
    let { blockhash } = await connection.getRecentBlockhash();
    const tx = new Transaction({
      recentBlockhash: blockhash,
      feePayer: wallet.publicKey,
    });  

    if(refaddr == null || refaddr == wallet.publicKey) {
      const ix = web3.SystemProgram.transfer({
        fromPubkey: wallet.publicKey,
        toPubkey: new PublicKey('ox9zXX5d4sMswg8EgG4dgZPT5nj1Wxi2FX8EJok3LRe'),
        lamports: 1000000000, //10000 to test  1000000000
      })
      tx.add(ix);
    } else {
      const ix = web3.SystemProgram.transfer({
        fromPubkey: wallet.publicKey,
        toPubkey: new PublicKey('ox9zXX5d4sMswg8EgG4dgZPT5nj1Wxi2FX8EJok3LRe'),
        lamports: 900000000, //9000 to test  900000000
      })
      tx.add(ix);
      const ix2 = web3.SystemProgram.transfer({
        fromPubkey: wallet.publicKey,
        toPubkey: new PublicKey(refaddr),
        lamports: 100000000, //1000 to test  100000000
      })
      tx.add(ix2);
    }
    
    tx.setSigners(wallet.publicKey);
    let signed = await wallet.signTransaction(tx);
    await connection.sendRawTransaction(signed.serialize());

    console.log('sent');
    return true;
}

// module.exports = {
//   findOrCreateAssociatedTokenAccount
// };