import { SnackbarProvider } from 'notistack';
import ConnectionContextProvider from './utils/connection';
import WalletContextProvider from './utils/wallet';
import AccessContextProvider from './utils/access';

import Home from './Home'
import Rarity from './rarity/Rarity'
import './App.scss';

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <ConnectionContextProvider>
        <WalletContextProvider>
          <AccessContextProvider>
            <Home />
          </AccessContextProvider>
        </WalletContextProvider>
      </ConnectionContextProvider>
    </SnackbarProvider>
  )
}

export default App;